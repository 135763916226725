<template>
  <div
    :class="{
      'page-header': true,
      'page-header--light': light,
      'page-header--dark': dark,
      'page-header--no-padding': noPadding,
    }"
  >
    <content-wrapper-block>
      <bread-crumbs :dark="darkCrumb" />
      <component
        :is="!linkTo ? 'a' : 'router-link'"
        v-if="link || linkTo"
        :to="linkTo"
        class="page-header__link--back"
        @click="!linkTo ? $router.go(-1) : false"
      >
        <span class="gg-chevron-left" />
        Вернуться назад
      </component>
      <div
        :class="{
          'page-header__block': true,
          'page-header__block--inline': inline,
        }"
      >
        <div class="page-header__top">
          <div class="page-header__top-slot">
            <slot name="top" />
          </div>
          <page-title main>
            <span v-html="title" />
          </page-title>
          <div class="page-header__top-panel">
            <slot name="title-right" />
          </div>
        </div>
        <div class="page-header__bottom">
          <slot />
        </div>
      </div>
    </content-wrapper-block>
  </div>
</template>

<script>
import PageTitle from "@/components/elements/PageTitle";
import ContentWrapperBlock from "@/components/blocks/ContentWrapperBlock";
import BreadCrumbs from "@/components/elements/BreadCrumbs";

export default {
  name: "PageHeader",
  components: {
    BreadCrumbs,
    PageTitle,
    ContentWrapperBlock,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    light: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    darkCrumb: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    linkTo: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.page-header {
  background-color: #231f20;
  color: #ffffff;
  padding-bottom: 30px;

  &__link--back {
    display: flex;
    align-items: center;
    margin-top: 15px;
    color: #231f20;
    font-family: Rubik;
    font-size: 14px;
    cursor: pointer;
    @media screen and (min-width: $screen-sm) {
      font-size: 18px;
    }
    &:hover {
      text-decoration: none;
    }
    .gg-chevron-left {
      box-sizing: border-box;
      position: relative;
      display: block;
      transform: scale(var(--ggs, 1));
      width: 18px;
      height: 18px;
      border: 2px solid transparent;
      border-radius: 100px;
    }
    .gg-chevron-left::after {
      content: "";
      display: block;
      box-sizing: border-box;
      position: absolute;
      width: 7px;
      height: 7px;
      border-bottom: 1px solid;
      border-left: 1px solid;
      transform: rotate(45deg);
      left: 6px;
      top: 4px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;

    &--inline {
      flex-direction: row;
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    &-panel {
      margin-left: 40px;
    }

    .date {
      margin-top: 15px;
      display: block;
    }
  }
  &__bottom {
    display: block;
    font-family: $font-family-rubik;
    font-weight: 300;
    font-size: 14px;
    line-height: 170%;
    letter-spacing: 0.03em;
    color: #ffffff;
    opacity: 0.5;

    @media screen and (min-width: $screen-sm) {
      font-size: 16px;
    }
  }

  &--light {
    background-color: #ffffff;
    color: #231f20;

    .page-header__bottom {
      color: #231f20;
      opacity: 0.7;
    }

    .content-wrapper {
      overflow: visible;
    }
  }
  &--dark {
    .page-header {
      &__block {
        display: block;
        align-items: center;
        flex-direction: row;

        @media (min-width: $screen-xs) {
          display: flex;
        }
      }

      &__bottom {
        opacity: 1;
      }
    }
  }
  &--no-padding {
    padding-bottom: 0px;
  }
}
</style>
