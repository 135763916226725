var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "page-header": true,
        "page-header--light": _vm.light,
        "page-header--dark": _vm.dark,
        "page-header--no-padding": _vm.noPadding,
      },
    },
    [
      _c(
        "content-wrapper-block",
        [
          _c("bread-crumbs", { attrs: { dark: _vm.darkCrumb } }),
          _vm.link || _vm.linkTo
            ? _c(
                !_vm.linkTo ? "a" : "router-link",
                {
                  tag: "component",
                  staticClass: "page-header__link--back",
                  attrs: { to: _vm.linkTo },
                  on: {
                    click: function ($event) {
                      !_vm.linkTo ? _vm.$router.go(-1) : false
                    },
                  },
                },
                [
                  _c("span", { staticClass: "gg-chevron-left" }),
                  _vm._v(" Вернуться назад "),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              class: {
                "page-header__block": true,
                "page-header__block--inline": _vm.inline,
              },
            },
            [
              _c(
                "div",
                { staticClass: "page-header__top" },
                [
                  _c(
                    "div",
                    { staticClass: "page-header__top-slot" },
                    [_vm._t("top")],
                    2
                  ),
                  _c("page-title", { attrs: { main: "" } }, [
                    _c("span", { domProps: { innerHTML: _vm._s(_vm.title) } }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "page-header__top-panel" },
                    [_vm._t("title-right")],
                    2
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page-header__bottom" },
                [_vm._t("default")],
                2
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }